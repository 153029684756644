<template>
  <div class="header-inside">
    <div class="header-left">
      <div class="header-logo">
        <Logo
          :width="`24`"
          :longwidth="`141.73`"
        />
      </div>
      <div class="header-search">
        <component
          :is="Search"
        />
      </div>
      <component
        :is="LanguageMenu"
        :fsize="`sm`"
        :flag="18"
      />
    </div>
    <div class="header-right">
      <div class="header-accounts">
        <div class="box" valign="center">
          
          <!-- 로그아웃 상태 -->
          <template v-if="!user.login">
            <v-btn to="/auth/login" :title="$t('log-in')" variant="plain" fsize="md">
              <div class="box" gap="2xs" valign="center">
                <v-icon icon="mdi-account-circle-outline" fsize="lg" mgt="4xs" />
                {{ $t('log-in') }}
              </div>
            </v-btn>
            <v-btn to="/auth/join" :title="$t('sign-up')" variant="plain" color="light-blue-lighten-3" fsize="md">
              <div class="box" gap="2xs" valign="center">
                <v-icon icon="mdi-plus" fsize="md" />
                {{ $t('sign-up') }}
              </div>
            </v-btn>
            <v-btn to="/game/melody/create/" :title="$t('create-game')" variant="outlined" color="primary" mgt="4xs" mgl="xs" mgr="3xs">
              <v-icon icon="mdi-gamepad-outline" mgr="2xs" />
              {{ $t('create-game') }}
            </v-btn>
          </template>

          <!-- 로그인 상태 -->
          <template v-else>
            <NuxtLink :to="`/me`" class="box" gap="xs" valign="center">
              <v-avatar
                :image="user?.thumbnail"
                :color="globalVariables.userset.color"
                class="img-text"
              >
                <span fsize="sm">{{ user?.nickname?.slice(0, 1) }}</span>
              </v-avatar>
              <span>
                {{ user.nickname }}
              </span>
            </NuxtLink>
            <v-btn to="/game/melody/create/" :title="$t('create-game')" variant="outlined" color="secondary" mgt="4xs" mgl="xl" mgr="3xs">
              <v-icon icon="mdi-gamepad-outline" mgr="2xs" />
              {{ $t('create-game') }}
            </v-btn>
          </template>
          
          <!-- 메뉴 -->
          <component
            :is="VerticalMenu"
          />
          
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
// import Search from '~/component/element/search.vue'
// import LanguageMenu from '~/component/element/language-menu.vue'
// import VerticalMenu from '~/component/element/vertical-menu.vue'

export default {
  setup () {

    // 글로벌 스토어
    const { user } = storeToRefs(useAuthStore())

    // 메모리 비우기
    function restore () {
      Search.value = null
      LanguageMenu.value = null
      VerticalMenu.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const Search: any = shallowRef(null)
    const LanguageMenu: any = shallowRef(null)
    const VerticalMenu: any = shallowRef(null)
    const loadComponent = async () => {
      const Search_load = await import('~/component/element/search.vue')
      const LanguageMenu_load = await import('~/component/element/language-menu.vue')
      const VerticalMenu_load = await import('~/component/element/vertical-menu.vue')
      Search.value = Search_load.default || Search_load
      LanguageMenu.value = LanguageMenu_load.default || LanguageMenu_load
      VerticalMenu.value = VerticalMenu_load.default || VerticalMenu_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      user,

      // 컴포넌트
      Search,
      LanguageMenu,
      VerticalMenu
    }
  }
}
</script>